@font-face {
  font-family: "swansea";
  src: url("./fonts/swansea/Swansea.ttf");
  font-style: normal;
}

@font-face {
  font-family: "swansea";
  src: url("./fonts/swansea/SwanseaBold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "swansea";
  src: url("./fonts/swansea/SwanseaItalic.ttf");
  font-style: italic;
}

@font-face {
  font-family: "swansea";
  src: url("./fonts/swansea/SwanseaBoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

:root {
  color-scheme: light;
  --popper-anim-duration: 0.3s;
  --project-grid-columns: 3;
  --project-grid-gap: 20px;
  --max-content-width: 1200px;
  --box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
  --box-shadow-light: 0 5px 10px rgba(0, 0, 0, 0.3);

  --color-60: #fdfdfd;
  --color-30: #aaaaaa;
  --color-10: #247BA0;
  --color-10-light: hsl(214, 100%, 74%);
}

#root {
  /* height: 100%; */
}

body {
  background-color: var(--color-60);

  height: 100%;
  /* overflow: hidden; */
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif, 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif, 'Arial Narrow', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0 auto;
}

.main {
  /* position: relative; */
  /* border: 2px solid magenta; */
  /* height: 100%; */
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 50%;
}

.nav-bar-container {
  z-index: 150;
  top: 0;
  position: sticky;
  /* border: 1px solid yellow; */
  background-color: white;
  font-weight: bold;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin: 0 10px;
}

.nav-bar-content-container {
  color: black;
  padding: 20px;
  /* border: 1px solid magenta; */
  max-width: var(--max-content-width);
  margin: 0 auto;
  display: flex;
  text-transform: lowercase;
  font-size: max(min(1em, 3vw), 0.5em);
  user-select: none;

}

.nav-bar-content-container a {
  text-decoration: none;
  color: black;
}

.nav-bar-title {
  white-space: nowrap;
  /* border: 1px solid red; */
  margin-right: 20px;
}

.nav-bar-items-container {
  margin: 0 0 0 auto;
  display: inline-flex;
  gap: 1.5em;
  /* border: 1px solid magenta; */
}

.main-content-container {
  width: 100%;
  /* border: 1px solid red; */
  max-width: var(--max-content-width);
  margin: 0 auto;
  /* height: 200%; */
  padding: 20px;
}

p {
  margin: 0;
}

.desc {
  padding-top: 20px;
}





.name-plate {
  /* border: 1px solid red; */
  padding: 1em 0;
  width: fit-content;
  /* color: white; */
  margin: 0 auto;
  font-size: min(4em, 10vw);
  text-transform: lowercase;
  text-align: center;
}

.popper-anchor {
  /* border: 1px solid magenta; */
}



.popper {
  position: absolute;
  /* border: 2px solid red; */
  /* background-color: white; */
  /* transition: all ease-in-out var(--popper-anim-duration); */
  transition-property: left, right, top, bottom, z-index;
  transition-duration: var(--popper-anim-duration);
  transition-timing-function: ease-in-out;
}

.test-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  /* border: 1px solid red; */
  gap: 20px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes project-details-popup {
  0% {
    opacity: 0;
    transform: translate(-50%, 0) scale(0.75);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, 0) scale(1);
  }
}



.project-preview-container {
  transition: all ease-in-out 0.2s;
  height: 15em;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
  background-color: white;
  /* padding: 20px; */
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  outline: 2px solid transparent;
  /* border: 1px solid lightgreen; */
  outline-offset: -2px;
  display: flex;
  /* gap: 10px; */
  flex-direction: column;
}

.project-preview-container:hover {
  /* transform: scale(1.05); */
  /* outline: 2px solid var(--color-10); */
  /* outline-offset: 5px; */
  transform: translate(0, -10px);
}

.project-preview-header {
  /* background-color: #c92230; */
  color: white;
  padding: 10px;
  line-height: 2em;
  font-size: 1em;
}

.project-preview-content-container {
  padding: 10px 20px 20px 20px;
  flex: 1;
  /* border: 2px solid magenta; */
  /* background-color: lightblue; */
  position: relative;
}

.project-preview-image {
  z-index: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150%;
  height: 150%;
  /* object-fit: cover; */
  object-fit: cover;
  transform: translate(-50%, -50%) rotate3d(0, 0.5, 0.5, 20deg);
}

.project-preview-image-overlay {
  /* transition: all 0.2s linear; */
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1px);
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 1));
  padding: 10px;
}

/* .project-preview-container:hover .project-preview-image-overlay {
  backdrop-filter: blur(0px);
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 1));
} */

.project-preview-icon-container {
  display: flex;
  gap: 5px;
}

.project-preview-icon {
  width: 30px;
  height: 30px;
  /* border: 1px solid red; */
}

.project-preview-title {
  background-color: #c92230;
  /* border-bottom: 5px solid rgb(0, 0, 0); */
  color: white;
  padding: 10px;
  line-height: 1em;
  font-size: 2em;
  margin: 0;
  z-index: 1;
}

.project-title {
  font-size: 2.5em;
  line-height: 1em;
  margin: 0 0 10px 0;
}

.project-preview-description {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: black;
  font-style: italic;
}

.section-container {
  margin: 0 0 3rem 0;
}

.section-title {
  text-transform: lowercase;
  margin: 0 0 2rem 0;
  /* position: sticky; */
  /* top: 0; */
  border-bottom: 2px solid var(--color-10);
  padding-bottom: 5px;
  width: 25%;
  min-width: fit-content;
}

.about {
  text-align: justify;
  max-width: 900px;
  padding: 0 1.5em;
  margin: 0 auto;
  font-style: italic;
  color: #555;
  font-size: 1.2rem;
}

@media (max-width: 500px) {

  :root {
    font-size: 12px;
  }

}


.button, button {
  transition: all ease-in-out 0.2s;
  display: inline-block;
  font-size: 1em;
  text-decoration: none;
  color: white;
  font-weight: bold;
  background-color: var(--color-10);
  border: none;
  border-radius: 10px;
  padding: 10px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  box-shadow: var(--box-shadow-light);
  cursor: pointer;
  text-align: center;
}

.button {
  min-width: 120px;
}

.button:hover, button:hover {
  outline: 2px solid var(--color-10);
  outline-offset: 5px;
  /* transform: translate(0, -5px); */
}

.project-container {
  z-index: 100;
  position: absolute;
  top: 100px;
  left: 50%;
  width: calc(100% - 20px);
  max-width: 1300px;
  transform: translate(-50%, 0);
  animation: project-details-popup ease-in-out .3s;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.7);
  background-color: white;
  padding: 1.5em;
  border-radius: 10px;
  /* animation-fill-mode: forwards; */
  /* outline: 2px solid #9AE19D;
  outline-offset: 5px; */
}

.project-details-container {
  /* overflow: hidden; */
  /* border: 1px solid red; */
  display: flex;
  flex-flow: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.project-details {
  /* text-align: justify; */
  color: #555;
  /* font-size: 1.2em; */
  flex: 1;
  min-width: 300px;
  width: fit-content;
  /* border: 1px solid magenta; */
  /* margin: 0 20px auto 0; */
}

.project-images-container {
  flex: 1;
  min-width: 300px;
  max-width: 600px;
  max-height: 700px;
  height: 600px;
  border-radius: 10px;
}

.project-links-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.project-brief-container {
  border-bottom: 2px solid lightgray;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.close-button {
  cursor: pointer;
  transform: translate(10px, -10px);
  width: 50px;
  height: 50px;
  padding: 10px;
  float: right;
  /* background: radial-gradient(circle closest-side, rgba(0, 0, 0, 0.1) 0%, transparent 100%); */
  overflow: show;
  /* border: 1px solid magenta; */
  /* box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3); */
  /* transition: all 0.1s linear;
  outline: 2px solid transparent; */
}

.close-button:hover {
  /* outline: 2px solid black; */
}

.multi-image-container {
  /* width: 500px; */
  /* height: 500px; */
  overflow: hidden;
  background-color: #444;
  /* resize: both; */
  position: relative;
  /* display: flex; */
  /* flex-direction: column; */
}

/* .multi-image-image-wrapper {
  flex: 1;
  border: 1px solid magenta;
  width: 100%;
} */

.multi-image-image {
  object-fit: contain;
  user-select: none;
  display: block;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 500px;
}

.multi-image-controls-container {
  user-select: none;
  color: white;
  position: absolute;
  bottom: 0;
  height: fit-content;
  width: 100%;
  /* border: 1px solid magenta; */
  /* display: flex; */
  /* z-index: 20; */
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  opacity: 1;
  transition: opacity 0.1s linear;
}

.multi-image-controls-label {
  display: inline-block;
  margin: auto;
  font-size: 20px;
  line-height: 20px;
}

.multi-image-control-button {
  cursor: pointer;
  display: inline-block;
  height: 50px;
  width: 50px;
  /* border: 1px solid red; */
}

.multi-image-control-button.prev {
  float: left;
}

.multi-image-control-button.next {
  transform: rotate(180deg);
  float: right;
}

.multi-image-container:hover>.multi-image-controls-container {
  opacity: 1;
}